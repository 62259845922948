<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-select
            style="width: 350px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!-- <a-form-item>
          <a-select
            style="width: 200px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item> -->
        <a-form-item>
          <a-range-picker
            :placeholder="['开始时间', '结束时间']"
            style="width: 400px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/compensationUserProjects', page)"
            >导出</a-button
          >
        </a-form-item>
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="sex" slot-scope="text">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="status" slot-scope="text">
          <a-tag v-if="text == '1'" color="orange"> 待提交 </a-tag>
          <a-tag v-if="text == '2'" color="green"> 已通过 </a-tag>
          <a-tag v-if="text == '3'" color="red"> 已拒绝 </a-tag>
          <a-tag v-if="text == '4'" color="orange"> 待审核 </a-tag>
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="switcher"
            @click="() => openeList(record)"
            >工资审核</a-button
          >
          <!--  -->
          <a-button
            v-if="!platformCode"
            type="link"
            size="small"
            icon="switcher"
            @click="() => openeDetail(record)"
            >工资发放</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      title="提交审核"
      v-if="uploadVisible"
      :visible="true"
      @ok="uploadData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="uploadVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="uploadModel"
        :rules="uploadodelRules"
        :model="uploadModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="签名图片" prop="img_url">
          <custom-file-list
            :img_url.sync="uploadModel.img_url"
            :type="'multiple'"
            @changeCustomFile="changeImgUrl"
          />
        </a-form-model-item>
        <a-form-model-item label="考勤记录" prop="clock_img_url">
          <custom-file-list
            :img_url.sync="uploadModel.clock_img_url"
            :type="'multiple'"
            @changeCustomFile="changeclockImg"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>

    <a-modal
      title="审核"
      :visible="examineVisible"
      @ok="UpdateexamineData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="UpdateexamineForm"
        :rules="examineRules"
        :model="examineData"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="审核" prop="state">
          <a-select placeholder="请审核" v-model="examineData.state">
            <a-select-option value="2">通过</a-select-option>
            <a-select-option value="3">拒绝</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="拒绝理由"
          prop="refuse_reason"
          v-if="examineData.state == 3"
        >
          <a-input
            type="textarea"
            row="2"
            v-model="examineData.refuse_reason"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 发工资弹框 -->
    <a-modal
      title="工资管理"
      v-if="SetWageisible"
      :visible="SetWageisible"
      @ok="SetWageData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="SetWageisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="SetWageModel"
        :rules="SetWageModelRules"
        :model="SetWageform"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="应发工资" prop="grant_fee">
          <a-input
            v-model="SetWageform.grant_fee"
            suffix="元"
            placeholder="请输入应发工资"
          ></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="个税率" prop="personal_income_tax">
          <a-input
            v-model.number="SetWageform.personal_income_tax"
            suffix="%"
            placeholder="请输入应扣个税率"
          ></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="年" prop="grant_year">
          <a-select v-model="SetWageform.grant_year" @change="grantYear">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option
              v-for="(item, index) in year"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="月" prop="grant_month">
          <a-select v-model="SetWageform.grant_month" @change="grantMonth">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option
              v-for="(item, index) in month"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="打卡天数">
          <a-tag color="blue" v-for="(item, index) in cloclDays" :key="index">
            {{ item.uname }}({{ item.days }}天)
          </a-tag>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 工资记录 -->
    <a-modal
      title="工资记录"
      width="1400px"
      :visible="vLogVisible"
      @ok="vLogClose"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vLogClose"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="vLogPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 200px"
              v-model="vLogPage.work_id"
              placeholder="请选择工种"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in jobJson"
                showSearch
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="
                $exportFun('/export/compensationUserTotalUsers', vLogPage)
              "
              >导出</a-button
            >
          </a-form-item>
          <!-- v-if='!platformCode' -->
          <a-form-item v-if="!platformCode">
            <a-button
              icon="money-collect"
              :disabled="selectedRowKeys.length == 0"
              @click="() => openSetWage(selectedRowKeys.join(','))"
              >批量发工资</a-button
            >
          </a-form-item>
          <a-form-item v-if="platformCode">
            <a-button icon="check-circle" @click="() => openwageDetail('')"
              >查看待审核</a-button
            >
          </a-form-item>
          <a-form-item v-if="!platformCode">
            <a-button icon="check-circle" @click="() => openwageDetail('alled')"
              >已发放记录</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="vLogColumns"
        :data-source="vLogList"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="{
          total: vLogPage.totalRow,
          defaultPageSize: vLogPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="user_id"
      >
        <template slot="action" slot-scope="text, record">
          <!--v-if='!platformCode'  -->

          <a-button
            type="link"
            size="small"
            icon="money-collect"
            v-if="!platformCode"
            @click="() => openSetWage(record.user_id)"
            >发工资</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="switcher"
            @click="() => openwageDetail(record)"
            >工资详情</a-button
          >
        </template>
      </a-table>
    </a-modal>
    <!-- 工资审核记录 -->
    <a-modal
      title="工资审核记录"
      width="1400px"
      :visible="listLogVisible"
      @ok="listLogVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="listLogVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="listLogPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 160px" v-model="listLogPage.state">
              <a-select-option value=""> 请选择审核状态 </a-select-option>
              <a-select-option
                v-for="(item, index) in wageState"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" @click="geteList" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              v-if="platformCode"
              :disabled="eListDetailKeys.length == 0"
              @click="aduit(eListDetailKeys.join(','))"
              icon="check-circle"
              >批量审核</a-button
            >
          </a-form-item>
          <!-- <div
            v-if="
              !platformCode &&
              wageDetailPageList.length > 0 &&
              wageDetailPage.user_id == ''
            "
          >
            <a-form-model-item label="累计发放工资:">
              {{ wageDetailPageList[0].total_fee }}
            </a-form-model-item>
            <a-form-model-item label="总剩余钱数:">
              {{ wageDetailPageList[0].surplus_fee }}
            </a-form-model-item>
          </div> -->
        </a-form>
      </div>
      <a-table
        :columns="listLogColumns"
        :data-source="listLogList"
        :row-selection="rowSelection"
        :pagination="{
          total: listLogPage.totalRow,
          defaultPageSize: listLogPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="orange" v-if="text == 1"> 待审核 </a-tag>
          <a-tag color="green" v-if="text == 2"> 审核通过 </a-tag>
          <a-tag color="red" v-if="text == 3"> 审核拒绝 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text">
          <view-file :data="text"></view-file>
        </template>

        <template slot="action" slot-scope="text, record">
          <!-- v-if="platformCode && record.state == 1" -->
          <a-button
            v-if="platformCode && record.state == 1"
            type="link"
            size="small"
            icon="check-circle"
            @click="() => aduit(record.id)"
            >审核</a-button
          >
          <a-button
            v-if="!platformCode && record.state == 3"
            type="link"
            size="small"
            icon="cloud-upload"
            @click="() => uploadWork(record, true)"
            >重新提交</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="user"
            @click="() => openUserlist(record)"
            >查看详情</a-button
          >
          <!--  -->

          <a-button
            v-if="!platformCode && (record.state == 1 || record.state == 3)"
            type="link"
            size="small"
            icon="delete"
            @click="() => delStateConfirm(record.id)"
            >撤销审核</a-button
          >
        </template>
      </a-table>
    </a-modal>

    <!-- 查看人员 -->
    <a-modal
      title="查看人员"
      width="1400px"
      :visible="vUserVisible"
      @ok="vUserVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vUserVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="vUserPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="vUserPage.grant_year">
              <a-select-option value=""> 请选择年 </a-select-option>
              <a-select-option
                v-for="(item, index) in year"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="vUserPage.grant_month">
              <a-select-option value=""> 请选择月 </a-select-option>
              <a-select-option
                v-for="(item, index) in month"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getUserDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationUser', vUserPage)"
              >导出公司审定表</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationUserDetail', vUserPage)"
              >导出工资所得表</a-button
            >
          </a-form-item>
          <!-- <div
            v-if="
              !platformCode &&
              wageDetailPageList.length > 0 &&
              wageDetailPage.user_id == ''
            "
          >
            <a-form-model-item label="累计发放工资:">
              {{ vUserList[0].total_fee }}
            </a-form-model-item>
            <a-form-model-item label="总剩余钱数:">
              {{ vUserList[0].surplus_fee }}
            </a-form-model-item>
          </div> -->
        </a-form>
      </div>
      <a-table
        :columns="vUserColumns"
        :data-source="vUserList"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="{
          total: vUserPage.totalRow,
          defaultPageSize: vUserPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="grant_year" slot-scope="text, record">
          {{ record.grant_year }}年{{ record.grant_month }}月
        </template>
        <template slot="action" slot-scope="text, record">
          <!--v-if='!platformCode'  -->

          <a-button
            v-if="!platformCode && record.state != 2 && record.state != 4"
            type="link"
            size="small"
            icon="money-collect"
            @click="() => openUpdateWage(record)"
            >修改</a-button
          >
          <a-button
            v-if="!platformCode && record.state != 2 && record.state != 4"
            type="link"
            size="small"
            icon="delete"
            @click="() => delConfirm(record.id)"
            >删除</a-button
          >
        </template>
      </a-table>
    </a-modal>

    <!-- 工资详情 -->
    <a-modal
      title="工资详情"
      width="1300px"
      :visible="wageDetailVisible"
      @ok="wageDetailVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="wageDetailVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px; padding-bottom: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="wageDetailPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="wageDetailPage.grant_year">
              <a-select-option value=""> 请选择年 </a-select-option>
              <a-select-option
                v-for="(item, index) in year"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 120px" v-model="wageDetailPage.grant_month">
              <a-select-option value=""> 请选择月 </a-select-option>
              <a-select-option
                v-for="(item, index) in month"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select style="width: 160px" v-model="wageDetailPage.state">
              <a-select-option value=""> 请选择审核状态 </a-select-option>
              <a-select-option
                v-for="(item, index) in wageState"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" @click="getWageDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="$exportFun('/export/compensationUser', wageDetailPage)"
              >导出公司审定表</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              :icon="getExicon.icon"
              :disabled="getExicon.ed"
              @click="
                $exportFun('/export/compensationUserDetail', wageDetailPage)
              "
              >导出工资所得表</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              v-if="!platformCode"
              :disabled="wageDetailKeys.length == 0"
              @click="() => uploadWork(record, false)"
              icon="check-circle"
              >批量提交审核</a-button
            >
          </a-form-item>
          <div
            v-if="
              !platformCode &&
              wageDetailPageList.length > 0 &&
              wageDetailPage.user_id == ''
            "
          >
            <a-form-model-item label="累计发放工资:">
              {{ wageDetailPageList[0].total_fee }}
            </a-form-model-item>
            <a-form-model-item label="总剩余钱数:">
              {{ wageDetailPageList[0].surplus_fee }}
            </a-form-model-item>
          </div>
        </a-form>
      </div>

      <a-table
        :columns="wageDetailPageColumns"
        :data-source="wageDetailPageList"
        :row-selection="addrowSelection"
        :pagination="{
          total: wageDetailPage.totalRow,
          defaultPageSize: wageDetailPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="orange" v-if="text == 1"> 待提交 </a-tag>
          <a-tag color="green" v-if="text == 2"> 审核通过 </a-tag>
          <a-tag color="red" v-if="text == 3"> 审核拒绝 </a-tag>
          <a-tag color="pink" v-if="text == 4"> 待审核 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text">
          <span v-if="text == null || text == ''">暂无图片</span>
          <img
            v-else
            v-for="(item, index) in text.split(',')"
            :key="index"
            :src="item + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
            style="width: 90px; height: 60px; margin: 3px 0px"
            @click="
              imgvisible = true;
              viewImgData = item;
            "
            alt=""
          />
        </template>
        <template slot="grant" slot-scope="text, record">
          {{ record.grant_year }}年{{ record.grant_month }}月
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button
            v-if="!platformCode && record.state != 2 && record.state != 4"
            type="link"
            size="small"
            icon="money-collect"
            @click="() => openUpdateWage(record)"
            >修改</a-button
          >
          <a-button
            v-if="!platformCode && record.state == 1"
            type="link"
            size="small"
            icon="delete"
            @click="() => delConfirm(record.id)"
            >删除</a-button
          >
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      selectedRowKeys: [],
      SetWageisible: false,
      SetWageModelRules: {
        grant_fee: [
          { required: true, message: "请输入应发工资", trigger: "change" },
        ],
        // personal_income_tax: [
        //   { required: true, message: "请输入应扣个税", trigger: "change" },
        // ],
        grant_year: [
          { required: true, message: "请输入发放工资，年", trigger: "change" },
        ],
        grant_month: [
          { required: true, message: "请输入发放工资，月", trigger: "change" },
        ],
      },
      SetWageform: {
        id: "",
        state: 1,
        project_id: undefined,
        user_ids: "",
        grant_fee: "",
        // personal_income_tax: "",
        grant_year: "",
        grant_month: "",
      },
      tableHeigt: 0,
      vLogVisible: false,
      listLogVisible: false,
      vLogColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        {
          title: "身份证",
          dataIndex: "idno",
        },
        {
          title: "银行卡号",
          dataIndex: "bank_card",
        },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
        },
        {
          title: "所属项目",
          dataIndex: "pname",
        },

        {
          title: "应发放费用",
          dataIndex: "grant_fee",
          width: 105,
        },
        {
          title: "个税金额",
          dataIndex: "personal_income_tax_fee",
          width: 100,
        },
        {
          title: "实际发放费用",
          dataIndex: "practical_grant_fee",
          width: 120,
        },
        {
          title: "操作",
          width: 210,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      vUserColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        {
          title: "身份证",
          dataIndex: "idno",
        },
        {
          title: "银行卡号",
          dataIndex: "bank_card",
        },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
        },
        {
          title: "所属项目",
          dataIndex: "pname",
        },

        {
          title: "应发放费用",
          dataIndex: "grant_fee",
          width: 105,
        },
        {
          title: "个税金额",
          dataIndex: "personal_income_tax_fee",
          width: 100,
        },
        {
          title: "实际发放费用",
          dataIndex: "practical_grant_fee",
          width: 120,
        },
        {
          title: "工资年月",
          dataIndex: "grant_year",
          scopedSlots: { customRender: "grant_year" },
          width: 105,
        },
        {
          title: "操作",
          width: 210,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      vLogList: [],
      vLogPage: {
        key: "",
        start: 1,
        totalRow: 0,
        pageSize: 20,
        limit: 20,
        project_id: "",
        work_id:""
        // grant_year: "",
        // grant_month: "",
        // state: "",
        // start_time: "",
        // end_time: "",
      },
      vUserVisible: false,
      vUserList: [],
      vUserPage: {
        id: "",
        key: "",
        start: 1,
        totalRow: 0,
        pageSize: 20,
        limit: 20,
        project_id: "",
        grant_year: "",
        grant_month: "",
      },
      listLogList: [],
      listLogPage: {
        key: "",
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        state: "",
        project_id: "",
      },
      listLogColumns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        {
          title: "签名",
          dataIndex: "img_url",
          key: "img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "考勤",
          dataIndex: "clock_img_url",
          key: "clock_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "提交时间",
          dataIndex: "create_time",
        },
        {
          title: "审核人",
          dataIndex: "cname",
          width: 100,
        },
        {
          title: "审核状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
        },
        {
          title: "审核时间",
          dataIndex: "company_audit_time",
        },
        {
          title: "拒绝原因",
          dataIndex: "refuse_reason",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      viewImgData: "",

      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "项目编码",
          dataIndex: "code",
        },
        {
          title: "项目名称",
          dataIndex: "name",
        },
        {
          title: "总金额",
          dataIndex: "total_grant_fee",
        },
        {
          title: "已发工资金额",
          dataIndex: "total_actual_grant_fee",
        },
        {
          title: "剩余金额",
          dataIndex: "surplus_fee",
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: undefined,
        start_time: "",
        end_time: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
      year: [],
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      wageState: [
        {
          id: 1,
          name: "待提交",
        },
        {
          id: 2,
          name: "审核通过",
        },
        {
          id: 3,
          name: "审核拒绝",
        },
        {
          id: 4,
          name: "待审核",
        },
      ],
      surplus_fee: "", // 剩余金额
      wageDetailVisible: false,
      wageDetailPage: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        project_id: "",
        user_id: "",
        key: "",
        grant_year: "",
        grant_month: "",
        start_time: "",
        end_time: "",
        state: "",
      },
      wageDetailPageres: {},
      wageDetailPageList: [],
      wageDetailPageColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 100,
        },
        {
          title: "年月",
          dataIndex: "grant_year",
          scopedSlots: { customRender: "grant" },
        },
        {
          title: "应发工资",
          dataIndex: "grant_fee",
        },
        {
          title: "个税金额",
          dataIndex: "personal_income_tax_fee",
        },
        {
          title: "实际发放工资",
          dataIndex: "practical_grant_fee",
        },
        // {
        //   title: "发放时间",
        //   dataIndex: "create_time",
        // },
        {
          title: "审核状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
        },
        {
          title: "拒绝原因",
          dataIndex: "refuse_reason",
        },
        // {
        //   title: "签名文件",
        //   dataIndex: "img_url",
        //   width: 120,
        //   scopedSlots: { customRender: "idno_positive_img_url" },
        // },
        {
          title: "操作",
          width: 180,
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      examineData: {
        ids: "",
        state: undefined,
        refuse_reason: "",
      },
      platformCode: window.localStorage.getItem("platformCode") != "labor_team",
      uploadVisible: false,
      uploadModel: {
        id: "",
        project_id: "",
        compensation_user_ids: "",
        img_url: "",
        clock_img_url:""
      },
      uploadodelRules: {
        img_url: [
          { required: true, message: "请上传签名图片", trigger: "change" },
        ],
      },
      wageDetailKeys: [],
      eListDetailKeys: [],
      isAddUpdate: false,
      formClockDay: {
        start: 1,
        limit: 999999,
        project_id: "",
        user_ids: "",
        clock_year: "",
        clock_month: "",
      },
      cloclDays: [],
      jobFrom: {
        project_id: "",
      },
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
    // 批量审核
    rowSelection() {
      return {
        selectedRowKeys: this.eListDetailKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.eListDetailKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.state != 1,
            name: "已审核",
          },
        }),
      };
    },
    addrowSelection() {
      return {
        selectedRowKeys: this.wageDetailKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.wageDetailKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.state != 1,
            name: "已审核",
          },
        }),
      };
    },
  },
  mounted() {
    this.getList();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
      let date = new Date();
      let y = date.getFullYear() + 1; //获取完整的年份(4位)
      for (let i = 0; i < 30; i++) {
        y = y - 1;
        this.year.push(y);
      }
    });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ ...this.jobFrom });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 已发工资工资记录弹框
    openeList(data) {
      console.log(data);
      this.listLogPage.project_id = data.id;
      this.listLogVisible = true;
      this.geteList();
    },
    // 工资审核记录
    async geteList() {
      let res = await Api.eList({ ...this.listLogPage });
      if (res && res["code"] == "0") {
        this.listLogList = res.page.list;
      } else {
        this.listLogList = [];
        this.$message.error(res.message);
      }
    },
    // 查看审核数据下人员
    openUserlist(data) {
      this.vUserPage.project_id = data.project_id;
      this.vUserPage.id = data.id;
      this.vUserVisible = true;
      this.getUserDetail();
    },
    async getUserDetail() {
      let res = await Api.UserDetail({ ...this.vUserPage });
      if (res && res["code"] == "0") {
        this.vUserList = res.page.list;
      } else {
        this.vUserList = [];
        this.$message.error(res.message);
      }
    },
    // 工资记录弹框
    openeDetail(data) {
      this.surplus_fee = data.surplus_fee; // 该项目可用金额
      console.log(data);
      this.vLogPage.project_id = data.id;
      this.SetWageform.project_id = data.id;
      this.formClockDay.project_id = data.id;
      this.jobFrom.project_id = data.id;
      this.vLogVisible = true;
      this.getDetail();
      this.getWorkJson();
    },
    async getDetail() {
      let res = await Api.Detail({ ...this.vLogPage });
      if (res && res["code"] == "0") {
        this.vLogList = res.page.list;
      } else {
        this.vLogList = [];
        this.$message.error(res.message);
      }
    },
    //  工资详情
    openwageDetail(data) {
      console.log(data);
      if (data == "") {
        this.wageDetailPage.user_id = "";
        // this.wageDetailPage.state = 1;
      } else if (data == "alled") {
        this.wageDetailPage.user_id = "";
        this.wageDetailPage.state = "";
      } else {
        this.wageDetailPage.user_id = data.user_id;
        this.wageDetailPage.state = "";
      }

      this.wageDetailPage.project_id = this.vLogPage.project_id;
      this.uploadModel.project_id = this.vLogPage.project_id;
      this.wageDetailVisible = true;
      this.getWageDetail();
      this.eListDetailKeys = [];
      this.wageDetailKeys = [];
    },
    async getWageDetail() {
      let res = await Api.WageDetail({ ...this.wageDetailPage });
      if (res && res["code"] == "0") {
        this.wageDetailPageList = res.page.list;
        this.wageDetailPageres = res.page;
      } else {
        this.wageDetailPageList = [];
        this.$message.error(res.message);
      }
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },
    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getWageDetail();
        this.getList();
        this.getDetail();
      } else {
        this.$message.error(res.message);
      }
    },
    // 删除审核记录确认
    delStateConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要撤销审核吗?",
        onOk() {
          that.delState(id);
        },
        onCancel() {},
      });
    },
    // 删除
    async delState(id) {
      let res = await Api.StateDelete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.geteList();
      } else {
        this.$message.error(res.message);
      }
    },
    openSetWage(ids) {
      console.log(ids);
      this.isAdd = true;
      this.SetWageisible = true;
      this.SetWageform.id = "";
      this.SetWageform.user_ids = ids;
      this.formClockDay.user_ids = ids;
      this.SetWageform.grant_fee = "";
      this.SetWageform.personal_income_tax = "";
      this.SetWageform.grant_year = "";
      this.SetWageform.grant_month = "";
      this.cloclDays = [];
    },
    openUpdateWage(data) {
      console.log(data);
      this.isAdd = false;
      this.SetWageisible = true;
      this.SetWageform.id = data.id;
      this.SetWageform.project_id = data.project_id;
      this.SetWageform.user_ids = data.user_id;
      this.SetWageform.grant_fee = data.grant_fee;
      this.SetWageform.personal_income_tax = data.personal_income_tax;
      this.SetWageform.grant_year = data.grant_year;
      this.SetWageform.grant_month = data.grant_month;
    },

    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      if (res && res.code == "0") {
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      } else {
        this.list = [];
        this.$message.error(res.message);
      }
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    SetWageData() {
      this.$refs.SetWageModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.SetWageform["id"]) {
            res = await Api.wageUpdate({ ...this.SetWageform });
          } else {
            res = await Api.wageSave({ ...this.SetWageform });
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.SetWageisible = false;
            this.getWageDetail();
            this.getDetail();
            if (this.vUserPage.project_id != "") {
              this.getUserDetail();
            }
            this.list();
            this.wageDetailKeys = [];
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    hideModal() {
      this.imgvisible = false;
    },

    vLogClose() {
      this.vLogVisible = false;
    },
    // 关闭窗口
    cancel() {
      this.examineVisible = false;
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
    getdTime(t, v) {
      this.vLogPage.start_time = v[0];
      this.vLogPage.end_time = v[1];
    },
    aduit(ids) {
      this.examineData.ids = ids;
      this.examineVisible = true;
    },
    /**
     * 审核
     *
     */
    UpdateexamineData() {
      this.$refs.UpdateexamineForm.validate(async (valid) => {
        if (valid) {
          let res = await Api.Updateexamine({ ...this.examineData });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.examineVisible = false;
            this.geteList();
            this.eListDetailKeys = [];
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    uploadWork(data, type) {
      this.isAddUpdate = type;
      if (!this.isAddUpdate) {
        this.uploadModel.compensation_user_ids = this.wageDetailKeys.join(",");
        this.uploadModel.img_url = "";
        this.uploadModel.clock_img_url = "";
        this.uploadModel.id = undefined;
      } else {
        this.uploadModel.id = data.id;
        this.uploadModel.project_id = data.project_id;
        this.uploadModel.compensation_user_ids = data.compensation_user_ids;
        this.uploadModel.img_url = data.img_url == null ? "" : data.img_url;
        this.uploadModel.clock_img_url = data.clock_img_url == null ? "" : data.clock_img_url;
      }
      console.log(this.uploadModel);
      this.uploadVisible = true;
    },
    uploadData() {
      this.$refs.uploadModel.validate(async (valid) => {
        if (valid) {
          let res;
          if (!this.isAddUpdate) {
            res = await Api.upload({ ...this.uploadModel });
          } else {
            res = await Api.updateUpload({ ...this.uploadModel });
          }
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.uploadVisible = false;
            this.getWageDetail();
            this.geteList();
            this.eListDetailKeys = [];
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 合同上传完成的事件
     * @author wheat
     * */
    changeImgUrl(url) {
      this.uploadModel.img_url = url.join(",");
    },
    changeclockImg(url) {
      this.uploadModel.clock_img_url = url.join(",");
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    oneListChange(selectedRowKeys) {
      this.eListDetailKeys = selectedRowKeys;
    },
    // 获取用户打卡天数
    async getUserClock() {
      let res = await Api.UserClock({ ...this.formClockDay });
      if (res && res.code == "0") {
        console.log(res);
        this.cloclDays = res.list;
      } else {
        this.$message.error(res.message);
      }
    },
    grantYear(val) {
      this.formClockDay.clock_year = val;
      if (this.formClockDay.clock_month != "") {
        this.getUserClock();
      }
    },
    grantMonth(val) {
      this.formClockDay.clock_month = val;
      if (this.formClockDay.clock_year != "") {
        this.getUserClock();
      }
    },
  },
};
</script>

<style scoped>
</style>

