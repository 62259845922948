import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/compensationUser/pageProjects',
        method: 'GET',
        params
    });
}
// 工资记录
export function Detail(params) {
    return request({
        url: '/admin/compensationUser/pageUser',
        method: 'GET',
        params
    });
}

// 新增发工资
export function wageSave(data) {
    return request({
        url: '/admin/compensationUser/save',
        method: 'POST',
        data
    });
}

// 工资详情
export function WageDetail(params) {
    return request({
        url: '/admin/compensationUser/pageDetail',
        method: 'GET',
        params
    });
}

// 修改发工资
export function wageUpdate(data) {
    return request({
        url: '/admin/compensationUser/update',
        method: 'POST',
        data
    });
}

// 删除工资详情
export function Delete(params) {
    return request({
        url: '/admin/compensationUser/delete',
        method: 'GET',
        params
    });
}

// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/work/getWorks',
        method: 'GET',
        params
    });
}

// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}

// 审核 
export function Updateexamine(params) {
    return request({
        url: '/admin/compensationUserState/aduit',
        method: 'GET',
        params
    });
}

// 上传签名文档  
export function upload(data) {
    return request({
        url: '/admin/compensationUserState/save',
        method: 'POST',
        data
    });
}

export function updateUpload(data) {
    return request({
        url: '/admin/compensationUserState/update',
        method: 'POST',
        data
    });
}

// 已发工资记录
export function eList(params) {
    return request({
        url: '/admin/compensationUserState/page',
        method: 'GET',
        params
    });
}
// 查看人员
export function UserDetail(params) {
    return request({
        url: '/admin/compensationUserState/pageCompensationUsers',
        method: 'GET',
        params
    });
}

// 删除审核记录
export function StateDelete(params) {
    return request({
        url: '/admin/compensationUserState/delete',
        method: 'GET',
        params
    });
}

// 获取打卡时间
export function UserClock(params) {
    return request({
        url: '/admin/clock/getNumberDays',
        method: 'GET',
        params
    });
}
